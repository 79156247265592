import React from "react";
import EmployeesList from "../components/EmployeesList/EmployeesList";

const Main = () => {
  return (
    <>
      <EmployeesList />
    </>
  );
};

export default Main;
